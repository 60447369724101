<template>
  <Section class="relative h-screen" id="home" v-smartnav>
      <div
        class="flex flex-col justify-center md:justify-center items-center w-full h-screen px-10 md:px-36 bg-white"
      >
        <p
          class="text-gray-800 uppercase font-bold text-4xl text-center md:text-7xl text-shadow"
        >
          Lets
          <span class="text-green-800 block">Hold Hands.</span>
        </p>

        <br /><br />
        <p class="text-gray-800 font-semibold text-center text-md md:text-lg mt-1">
          For donations and volunteering please reach out to us on
        </p>
        <br>
        <div class="text-gray-800 text-center font-medium">
          <p>donations@risemalawi.org</p>
          <p>volunteering@risemalawi.org</p>
        </div>
      </div>
  </Section>
</template>

<script>
export default {
  name: "Donate",
};
</script>

<style scoped>
.landing-parallax {
  /* The image used */
  background-image: url("../assets/images/hands.jpg");

  /* Set a specific height */
  height: 100vh;

  /* Create the parallax scrolling effect */
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
</style>
